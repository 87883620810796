import GrantsCards from "../../components/grantsCards/GrantsCards";


const PreviousGrants = () => {

  return (
    <>
      <h1 className="text-center mb-4">Your Previous Grants</h1>
      <GrantsCards />    
    </>
  );
};

export default PreviousGrants;